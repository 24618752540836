import { type Pregnancy } from "@/lib/db";
import { d, type GaAt } from "@/lib/core";
import { fmf, getGaAt, intergrowth } from "@/lib/core";
import { type StudyWithJsonData, type StudyData } from "@/types";

export type StudyAggregated = StudyWithJsonData & {
  obstetricWeeksGa: number | null;
  gaAtStudyAsDaysNumber: number | null;
  fmfEFW: number | null;
  efwCentileFMF: number | null;
  efwCentileINTERGROUTH: number | null;
  efwCentile: number | null;
  pAUT: number | null;
  ipAUCentile: number | null;
  ipACMP: number | null;
  relCerPlacentaria: number | null;
  cerebroPlacentalRatioCentile: number | null;
  ipDVP: number | null;
  operator: string | null;
  data: StudyData;
};

// Devuelve estudios en el rango del embarazo
export const getStudiesData = (
  studies?: StudyWithJsonData[],
  ga?: GaAt | null,
  pregnancy?: Pregnancy | null,
  fmfPref?: boolean
): StudyAggregated[] | undefined => {
  if (!ga || !studies || !pregnancy) return undefined;
  const result = studies
    ?.filter((study) => {
      const currPregnancyStart = d(
        pregnancy.lastMenstrualPeriodDate ?? pregnancy.createdAt
      );
      const istWithinRange = d(study.date).isBetween(
        currPregnancyStart,
        d(),
        "day",
        "[]"
      );
      return istWithinRange;
    })
    .map((study) => {
      const data = study?.data;
      const gaAtStudy = getGaAt(ga, study.date);

      const gaAtStudyAsDaysNumber =
        fmf.obstetricWeeksObjectToDaysNumber(gaAtStudy);
      const obstetricWeeksGa = fmf.daysToOW(gaAtStudyAsDaysNumber);

      const fmfEFW: number | null | undefined =
        fmf.calculateEFWFromBiometry({
          hc: data.HC?.value,
          ac: data.AC?.value,
          fl: data.FL?.value,
        }) ?? data.EFW?.value;

      // pPEF - percentil peso fetal
      const efwCentileFMF = fmf.centileFromFW(fmfEFW, gaAtStudyAsDaysNumber);
      const efwCentileINTERGROUTH = intergrowth.centileFromFW(
        fmfEFW,
        gaAtStudyAsDaysNumber
      );
      // Centile de peso según preferencia
      const efwCentile = fmfPref ? efwCentileFMF : efwCentileINTERGROUTH;

      // pAUT - Percentil Arterias uterinas
      const pAUT = fmf.uterineArteryPICentile(
        ((data?.rightUterinePulsatilityIndex ?? 0) +
          (data?.leftUterinePulsatilityIndex ?? 0)) /
          2,
        Math.round(gaAtStudyAsDaysNumber ?? 0) ?? null
      );

      // Indice pulsatilidad Arteria Umbilical Centile
      const ipAUCentile = fmf.umbilicalArteryPICentile(
        data.umbilicalArteryPulsatilityIndex,
        gaAtStudyAsDaysNumber
      );
      // Indice pulsatilidad arteria cerebral media Centile
      const ipACMP = fmf.middleCerebralPICentile(
        data.middleCerebralArteryPulsatilityIndex,
        gaAtStudyAsDaysNumber ?? 0
      );

      const relCerPlacentaria = fmf.calcCPR(
        data.middleCerebralArteryPulsatilityIndex,
        data.umbilicalArteryPulsatilityIndex
      );

      const cerebroPlacentalRatioCentile = fmf.cerebroPlacentalRatioCentile(
        relCerPlacentaria,
        gaAtStudyAsDaysNumber // 188
      );

      const ipDVP = fmf.doctusVenosusPICentile(
        data.ductusVenosusPulsatilityIndex,
        gaAtStudyAsDaysNumber ?? 0
      );

      return {
        ...study,
        obstetricWeeksGa,
        gaAtStudyAsDaysNumber,
        fmfEFW,
        efwCentileFMF,
        efwCentileINTERGROUTH,
        efwCentile,
        pAUT,
        ipAUCentile,
        ipACMP,
        relCerPlacentaria,
        cerebroPlacentalRatioCentile,
        ipDVP,
        operator: study?.data?.operator,
      };
    });

  return result as StudyAggregated[];
};
