import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

/**
 * @description returns 00 utc time for any date
 */
export function d(
  date?: dayjs.ConfigType,
  format?: dayjs.OptionType,
  locale = "es",
  strict?: boolean
) {
  return dayjs(date, format, locale, strict)
    .utc()
    .set("hour", 12)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0)
    .locale(locale);
}
